<template>
    <div>
		<b-modal ref="modalPrintInfosTiers" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("invoice.extrait_compte") }}
				</template>
			</template>

			<div class="row">
				<div v-if="form_message !== ''" class="col-12 alert alert-danger">{{ form_message }}</div>
				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="model"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="labelTitleTemplate"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
							:loading="loading_model"
			                group-values="models"
			                group-label="accountingplan_label"
			                :group-select="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div>

				<div class="col-12 form-group">
					<label for="languages" class="col-form-label">{{ $t("global.language") }}</label>
					<e-select
						v-model="localeSelected"
						id="languages"
						label="title"
						track-by="code"
						:options="locales"
						:allow-empty="false"
					/>
				</div>

				<div class="col-6 form-group">
					<label class="col-form-label">{{ $t("global.date_debut") }} *</label>
					<e-datepicker v-model="start_date" mode="date"></e-datepicker>
				</div>
				<div class="col-6 form-group">
					<label class="col-form-label">{{ $t("global.date_fin") }} *</label>
					<e-datepicker v-model="end_date" mode="date"></e-datepicker>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm" :disabled="isDisabled">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Common from '@/assets/js/common.js'
import Accounting from '@/mixins/Accounting.js'
import Vue from 'vue'

export default {
	name: "ModalExtraitCompte",
	mixins: [Accounting],
	data () {
		return {
			templates: [],
			formats: [],
			model: null,
			format: null,
			tiers_ids: null,
			processing: false,
			period: false,
			localeSelected: null,
			locales: this.getLocales(),
			start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
			end_date: new Date(),
			form_message: '',
			isDisabled: false,
			loading_model: false,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleFormat: this.getTrad("horse.search_format")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.model = null
			this.localeSelected = this.locales.find(lang => lang.code == (Vue.i18n.locale()))
			this.loading_model = true
			this.templates = await this.loadModelAccoutingPlan('extrait_compte')
			this.loading_model = false
		},
		openModal(tiers_ids, nb_selected = null) {
			this.isDisabled = false
			this.form_message = ''
			this.tiers_ids = tiers_ids
			if(nb_selected && nb_selected > 10) {
				this.isDisabled = true
				this.form_message = this.getTrad("global.error_nb_selected")
			}
			this.$refs.modalPrintInfosTiers.show()
		},
		async checkForm() {
			if(!this.model || !this.start_date || !this.end_date) {
				this.failureToast('toast.all_inputs')
				return false
			}
			this.processing = true
			await this.printModel(this.tiers_ids, this.model.model_id, this.model.model_label, this.start_date.toDateInputValue(), this.end_date.toDateInputValue(), this.localeSelected.code)
			this.processing = false
		},
	},
}
</script>